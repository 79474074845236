import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';

interface TutorialStep {
  route: string;
  content: string;
  title: string;
}

@Injectable({
  providedIn: 'root',
})
export class TutorialService {
  private tutorialContentSubject = new BehaviorSubject<string>('');
  private tutorialTitleSubject = new BehaviorSubject<string>('');
  private tutorialVisibleSubject = new BehaviorSubject<boolean>(false);

  tutorialContent$: Observable<string> = this.tutorialContentSubject.asObservable();
  tutorialTitle$: Observable<string> = this.tutorialTitleSubject.asObservable();
  tutorialVisible$: Observable<boolean> = this.tutorialVisibleSubject.asObservable();

  private steps: TutorialStep[] = [];
  private currentStepIndex: number = 0;

  constructor(private router: Router) {}

  // Set the steps for the tutorial
  setTutorialSteps(steps: TutorialStep[]) {
    this.steps = steps;
  }

  getStepsCount(): number {
    return this.steps.length;
  }

  // Methods to update the tutorial content
  startTutorial() {
    this.tutorialVisibleSubject.next(true);
    this.currentStepIndex = 0; // Start at the first step
    this.updateTutorialStep();
    this.navigateToStep(); // Navigate to the first step route
  }

  nextStep(): TutorialStep {
    this.currentStepIndex++;
    if (this.currentStepIndex < this.steps.length) {
      this.updateTutorialStep();
      this.navigateToStep(); // Navigate to the next step route
      return this.steps[this.currentStepIndex];
    } else {
      this.endTutorial();
      return { route: '', content: '', title: '' }; // Return empty step if tutorial ends
    }
  }

  previousStep(): TutorialStep {
    if (this.currentStepIndex > 0) {
      this.currentStepIndex--;
      this.updateTutorialStep();
      this.navigateToStep(); // Navigate to the previous step route
      return this.steps[this.currentStepIndex];
    } else {
      return { route: '', content: '', title: '' }; // Return empty step if no previous step
    }
  }

  endTutorial() {
    this.tutorialVisibleSubject.next(false);
    this.tutorialContentSubject.next('');
    this.tutorialTitleSubject.next('');
  }

  private updateTutorialStep() {
    const step = this.steps[this.currentStepIndex];
    this.tutorialContentSubject.next(step.content);
    this.tutorialTitleSubject.next(step.title);
  }

  getCurrentStep(): TutorialStep {
    return this.steps[this.currentStepIndex];
  }

  getCurrentStepIndex() {
    return this.currentStepIndex;
  }

  getSteps(): TutorialStep[] {
    return this.steps;
  }

  private navigateToStep() {
    const currentStep = this.steps[this.currentStepIndex];
    if (currentStep.route) {
      this.router.navigate([currentStep.route]); // Navigate to the route of the current step
    }
  }
}

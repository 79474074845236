import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-unauthorized',
    templateUrl: './unauthorized.component.html',
    styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit {
    heading: string = 'Whoops!';
    paragraph: string = 'This URL is valid but you are not authorized for this content.';

    constructor() {
        let error_report = JSON.parse(sessionStorage.getItem('error-report'));
        this.heading = error_report?.heading ? error_report?.heading : this.heading;
        this.paragraph = error_report?.message ? error_report?.message : this.paragraph;
        setTimeout(() => {
            sessionStorage.removeItem('error-report');
        }, 300);
    }

    ngOnInit(): void {
    }

}
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { GlobalSearchComponent } from './global-search/global-search.component';
import { NewGlobalSearchComponent } from './new-global-search/new-global-search.component';
// import { SearchResultsComponent } from './search-results/search-results.component';
import { NewSearchResultsComponent } from './new-search-results/new-search-results.component';
import { UserCheckComponent } from './usercheck/usercheck.component';
import { CitationsComponent } from './citations/citations.component';
import { CacheComponent } from './cache/cache.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { FreeTrialComponent } from './free_trial/free_trial.component';
import { SubExpiredComponent } from './sub_expired/sub_expired.component';
import { SearchResultsViewComponent } from './search-results-view/search-results-view.component';

const routes: Routes = [
  // { path: '', pathMatch: 'full', component: GlobalSearchComponent },
  { path: '', pathMatch: 'full', component: NewGlobalSearchComponent },
  { path: 'search-results-view', component: SearchResultsViewComponent},
  { path: 'redirect', pathMatch: 'full', component: UserCheckComponent },
  // { path: 'query', component: SearchResultsComponent },
  { path: 'query', component: NewSearchResultsComponent},
  { path: 'citations', component: CitationsComponent },
  { path: 'cache-system', component: CacheComponent },
  { path: 'expired', component: FreeTrialComponent },
  { path: 'plan-expired', component: SubExpiredComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ModalPopupService } from '../services/modalPopup.services';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { GlobalmodalpopupComponent } from '../globalmodalpopup/globalmodalpopup.component';
import { SharedService } from './../shared.service';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute, Event, NavigationEnd } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { UserService } from '../services/user.service';
import { GlobalConfirmationPopupComponent } from '../global-confirmation-popup/global-confirmation-popup.component';
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { time } from 'console';

@Component({
  selector: 'app-new-search-results',
  templateUrl: './new-search-results.component.html',
  styleUrls: ['./new-search-results.component.scss'],
})
export class NewSearchResultsComponent implements OnInit {
  @ViewChild('desktopSearch') desktopSearch: ElementRef;
  myForm: FormGroup;
  rows: number = 1;
  email: AbstractControl;

  @ViewChild('mobileSearch') mobileSearch: ElementRef;
  mForm: FormGroup;
  mrows: number = 1;
  msearch: AbstractControl;

  // Loader & Error Handling Variables
  isLoading: boolean = false;
  isListen: boolean = true;
  showSuccessToaster: boolean = false;
  showSuccessMsg: string = '';
  showErrorToaster: boolean = false;
  showErrorMsg: string = '';
  showSuggestions: boolean = false;
  resizeSearch: boolean = false;
  resizeMSearch: boolean = false;
  fileUploadLoader: boolean = false;

  clickEventSubscription: Subscription;
  downloadOptions: boolean = false;
  downloadPew: boolean = false;
  showMCategory: boolean = false;
  active_tab: string;
  rightPanelAction: boolean = false;
  dialogRef: MatDialogRef<any>;
  searchResponse: any = {};
  searchQuery: string = '';
  private timeout: any;
  graphType: string = '';
  allAsertions: any = [];
  searchSuggestions: any = [];
  placeholderText: string =
    'An assertion is a statement claiming factual validity or invalidity...';
  planType: string = '';
  planTypeFlag: boolean = true;
  currentRoute: string;
  search_query: string = '';

  constructor(
    private modalPopupService: ModalPopupService,
    private route: ActivatedRoute,
    public userService: UserService,
    private router: Router,
    private http: HttpClient,
    private sharedservice: SharedService,
    private dialog: MatDialog,
    private gs: FormBuilder,
    private ms: FormBuilder
  ) {
    this.currentRoute = '';
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
      }
      if (event instanceof NavigationEnd) {
        if (event.url && event.url.split('?')[0])
          this.currentRoute = event.url.split('?')[0];
        else this.currentRoute = event.url;
      }
    });

    this.route.queryParams.subscribe((params) => {
      this.searchQuery = params.search;
      console.log(this.searchQuery);
      let queryResponse = JSON.parse(sessionStorage.getItem('query_response'));
      this.allAsertions = JSON.parse(sessionStorage.getItem('all_assertions'));

      if (
        queryResponse &&
        queryResponse?.title == params.search &&
        queryResponse?.response?.resultCode == 1
      ) {
        this.searchResponse = queryResponse?.response;
      } else {
        // this.isLoading = true;
        this.userService.setLoaderEvent(true);
        let userId = localStorage.getItem('userId');
        let token = localStorage.getItem('access_token');
        this.userService
          .searchAnalysis(this.searchQuery, token, userId)
          .subscribe(
            (Response: any) => {
              // this.isLoading = false;
              this.userService.setLoaderEvent(false);
              if (Response.query && Response.resultCode == 1) {
                this.searchResponse = Response;
              } else if (Response.resultCode == 0) {
                this.userService.setLoaderEvent(false);
                setTimeout(() => {
                  if (this.planType !== 'Legit™ Premium') {
                    this.dialog.open(GlobalConfirmationPopupComponent, {
                      disableClose: true,
                    });
                    this.router.navigate(['/'], { queryParams: { id: userId } });
                  }
                  else {
                    const docId = localStorage.getItem('docId');
                    this.dialog.open(GlobalConfirmationPopupComponent, {
                      disableClose: true,
                      data: { userId, docId }, // Pass props only if planType is 'Legit™ Premium'
                    });
                    this.router.navigate(['/search-results-view'], {
                      queryParams: { assertion: docId },
                    });
                  }
                }, 200);
              } else {
                this.openToaster(
                  false,
                  true,
                  'Failed to retrieve search results for "' +
                    this.searchQuery +
                    '". Server is currently busy, please try again in some time.'
                );
              }
            },
            (error: any) => {
              this.isLoading = false;
              this.userService.sendErrorEmailReport(token);
              this.openToaster(
                false,
                true,
                'Failed to retrieve search results for "' +
                  this.searchQuery +
                  '". Server is currently busy, please try again in some time.'
              );
            }
          );
      }
    });

    this.planType = localStorage.getItem('plan_name');
    if (this.planType == 'Legit™ Lite') {
      this.planTypeFlag = false;
    }
    if (this.planType == 'Legit™ Professional') {
      this.planTypeFlag = false;
    }
    if (this.planType == 'Free Trial') {
      this.planTypeFlag = false;
    }
    this.clickEventSubscription = this.sharedservice
      .getClickEvent()
      .subscribe(() => {
        this.gridChange();
      });

    this.userService.appLoading$.subscribe((appLoad) => {
      if (appLoad) {
        this.isLoading = true;
      } else {
        this.isLoading = false;
      }
    });

    // search bar related code - Desktop Search Builder
    this.myForm = gs.group({
      email: [
        '',
        Validators.compose([Validators.required, Validators.minLength(4)]),
      ],
    });
    this.email = this.myForm.controls['email'];
    // this.myForm.valueChanges.subscribe((value) => {
    //     this.desktopSearch.nativeElement.style.height = 'auto';
    //     this.desktopSearch.nativeElement.style.height = `${this.desktopSearch.nativeElement.scrollHeight}px`;
    // });

    // search bar related code - Mobile Search Builder
    this.mForm = ms.group({
      msearch: [
        '',
        Validators.compose([Validators.required, Validators.minLength(4)]),
      ],
    });
    this.msearch = this.mForm.controls['msearch'];
    // this.mForm.valueChanges.subscribe((value) => {
    //     this.mobileSearch.nativeElement.style.height = 'auto';
    //     this.mobileSearch.nativeElement.style.height = `${this.mobileSearch.nativeElement.scrollHeight}px`;
    // });
  }

  openDialog(option: any) {
    this.modalPopupService.setPopup(option);
    setTimeout(() => {
      this.dialogRef = this.dialog.open(GlobalmodalpopupComponent, {
        panelClass: ['global-modal-popup', 'animate__animated', 'zoomIn'],
      });

      this.dialogRef.disableClose = true;
    }, 200);
  }

  ngOnInit(): void {
    this.userService.querySub$.subscribe((queryLoad) => {
      this.isLoading = false;
      if (queryLoad) {
        let queryResponse = JSON.parse(
          sessionStorage.getItem('query_response')
        );

        if (queryResponse && queryResponse?.response?.resultCode == 1) {
          this.searchResponse = queryResponse?.response;
        }
      }
    });
  }
  onChange(value: any, key: any) {
    // if(this.isListen){
    // 	if(key==='search_query'){
    // 		let val=(value.target.value)?(value.target.value.trim()):'';
    // 		if(val.length>0){
    // 			this.searchQuery=val.charAt(0).toUpperCase()+val.slice(1,);
    // 		}
    // 		else{
    // 			this.searchQuery='';
    // 		}
    // 		this.isListen=false;
    // 	}
    // }
    this.showSuggestions = true;
    let val = value.target.value ? value.target.value.trim() : '';
    this.searchQuery = val.charAt(0).toUpperCase() + val.slice(1);

    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      this.getSearchSuggestions();
    }, 1000);
  }

  preventEnterKey(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.searchAssertion();
    }
  }

  clearSearch(): void {
    this.searchQuery = ''; // Clear the search query bound to the textarea
    // Optionally, if you want to directly manipulate the DOM:
    const textarea = document.getElementById('hsearch') as HTMLTextAreaElement;
    if (textarea) {
      textarea.value = ''; // Reset the textarea value
    }
  }

  getSearchSuggestions() {
    let token = localStorage.getItem('access_token');
    this.userService.getSearchSuggestions(token, this.searchQuery).subscribe(
      (Response: any) => {
        if (Response.meta.code == 200) {
          this.searchSuggestions = Response.data?.assertions;
          this.isListen = true;
        } else {
          // this.openToaster(false, true, "Failed to retrieve search suggestions.");
        }
      },
      (error: any) => {
        // this.openToaster(false, true, "Failed to retrieve search suggestions.");
        console.log(error);
      }
    );
  }
  setSearchQuery(text: any) {
    this.searchQuery = text;
  }
  hideSuggestions() {
    this.showSuggestions = false;
  }

  searchAssertion() {
    let userId = localStorage.getItem('userId');
    let token = localStorage.getItem('access_token');

    sessionStorage.setItem('searchQuery', this.searchQuery);
    this.searchSuggestions = [];
    this.isLoading = true;

    this.userService.searchAnalysis(this.searchQuery, token, userId).subscribe(
      (Response) => {
        if (Response.resultCode === 0) {
          setTimeout(() => {
            this.fileUploadLoader = false;
            this.dialog.open(GlobalConfirmationPopupComponent, {
              disableClose: true,
            });
          }, 200);
        } else if (Response.query && Response.resultCode == 1) {
          if (
            Response?.searchBoxAdditionalInfo?.additionalString &&
            Response?.searchBoxAdditionalInfo?.additionalString != null
          ) {
            setTimeout(() => {
              this.fileUploadLoader = false;
              this.dialog.open(GlobalConfirmationPopupComponent, {
                disableClose: true,
              });
            }, 200);
          } else {
            this.redirectToAssertion(this.searchQuery);
          }
        } else {
          this.userService.sendErrorEmailReport(token);
          this.openToaster(
            false,
            true,
            'Failed to retrieve search results for "' +
              this.searchQuery +
              '". Server is currently busy, please try again in some time.'
          );
        }
      },
      (error: any) => {
        this.fileUploadLoader = false;
        this.userService.sendErrorEmailReport(token);
        this.openToaster(
          false,
          true,
          'Failed to retrieve search results for "' +
            this.searchQuery +
            '". Server is currently busy, please try again in some time.'
        );
      }
    );
  }

  redirectToAssertion(assertion: any) {
    const searchQuery = sessionStorage.getItem('searchQuery');
    const userId = localStorage.getItem('userId');
    const token = localStorage.getItem('access_token');
    this.userService
      .saveSearchHistory(searchQuery, userId, token)
      .subscribe((Response) => {
        if (Response.statusCode === 0) {
          console.log('History Adding Failed');
        } else {
          console.log('Assertion added to the history successfully');
        }
      });
    this.router.navigate(['/query'], { queryParams: { search: assertion } });
  }

  async generateExcel(searchResponse: any) {
    // Extract the content
    const assertion = searchResponse.query;
    const answerBox = searchResponse.searchBoxResults;
    const truthMeter = 100 - searchResponse.statistics.probabilityOfValidity;
    const formattedTruthMeter = this.formatTruthMeter(
      searchResponse.statistics.probabilityOfValidity,
      searchResponse?.probabilitySource
    );
    const newsAnnouncements = searchResponse.newsAgencies;
    const citations = searchResponse.supportingTop5;
    const additionalContext = searchResponse.debunkingTop5;
    const resultsFromWikipedia = searchResponse.search_results;

    // Create headers and corresponding values
    const headers = [
      'Assertion',
      'AnswerBox',
      'TruthMeter',
      'News/Announcement',
      'Supporting URLS',
      'Additional Context',
      'Results from Wikipedia',
    ];
    
    const values = [
      assertion,
      answerBox,
      formattedTruthMeter, // Plain value
      this.formatNewsAnnouncements(newsAnnouncements), // Formatted News/Announcement
      this.formatCitations(citations), // Formatted Citations
      this.formatAdditionalContext(additionalContext), // Formatted Additional Context
      this.formatResultsFromWikipedia(resultsFromWikipedia), // Formatted Results from Wikipedia
    ];

    // Create a new workbook and worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    // Set initial column widths
    worksheet.getColumn(1).width = 50;

    // Add headers and values to worksheet
    headers.forEach((header, index) => {
      // Add header in the first column
      const headerRow = worksheet.addRow([header]);
      headerRow.getCell(1).font = { bold: true, size: 14 };
      headerRow.getCell(1).alignment = {
        horizontal: 'center',
        vertical: 'middle',
      };

      // Adjust row height
      headerRow.height = this.calculateRowHeight(header);

      // Add corresponding value in the next row
      const valueRow = worksheet.addRow([values[index]]);
      valueRow.getCell(1).alignment = { wrapText: true, vertical: 'top' };

      // Apply specific formatting for TruthMeter
      if (header === 'TruthMeter') {
        valueRow.getCell(1).alignment = {
          horizontal: 'center',
          vertical: 'middle',
        };
      }

      valueRow.height = this.calculateRowHeight(values[index]);

      // Add one empty row for spacing
      worksheet.addRow([]);
    });

    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), 'tq.xlsx');
  }

  flattenArray(arr: any[]): string {
    return arr.map((obj) => JSON.stringify(obj)).join('; ');
  }

  formatTruthMeter(truthMeter: number, probabilitySource: number): string {
    let graphType = '';
    if (truthMeter >= 0 && truthMeter <= 20) {
      graphType = 'Very Unlikely';
    } else if (truthMeter >= 21 && truthMeter <= 40) {
      graphType = 'Unlikely';
    } else if (truthMeter >= 41 && truthMeter <= 60) {
      graphType = 'Partially True';
    } else if (truthMeter >= 61 && truthMeter <= 80) {
      graphType = 'Likely';
    } else {
      graphType = 'Very Likely';
    }

    let labelText = '';
    switch (probabilitySource) {
      case 0:
        labelText = 'Google Answer';
        break;
      case 1:
        labelText = 'Rank System 1';
        break;
      case 2:
        labelText = 'News System Counter';
        break;
      case 3:
        labelText = 'Human';
        break;
      case 4:
        labelText = 'Google Answer With ChatGPT';
        break;
      case 5:
        labelText = 'ChatGPT Answer & Result';
        break;
      default:
        labelText = 'Unknown';
    }

    return `${graphType}`;
  }

  formatCitations(citations: any[]): string {
    return citations
      .map((citation) => {
        const cite = citation.cite.split('. ')[0]; // Slice up to the first period
        return `
			**${cite}**
			${citation.url}
		  `
          .trim()
          .replace(/\n\s+/g, '\n');
      })
      .join('\n\n');
  }

  formatAdditionalContext(additionalContext: any[]): string {
    return additionalContext
      .map((context) => {
        return `
			**${context.title}**
			${context.snipet}
		  `
          .trim()
          .replace(/\n\s+/g, '\n');
      })
      .join('\n\n');
  }

  formatNewsAnnouncements(newsAnnouncements: any[]): string {
    return newsAnnouncements
      .map((newsAgency) => {
        return `
			${newsAgency.name}
			${newsAgency.urlSearchRes}
			**${newsAgency.title}**
			Reliability: ${newsAgency.newsAgency.adfontesmediaRank?.reliability} Bias: ${newsAgency.newsAgency.adfontesmediaRank?.bias}
		  `
          .trim()
          .replace(/\n\s+/g, '\n');
      })
      .join('\n\n');
  }

  formatResultsFromWikipedia(resultsFromWikipedia: any[]): string {
    return resultsFromWikipedia
      .map((result) => {
        if (result.title === 'Wiki') {
          return result.results
            .map((wikiResult: any) => {
              // Slice up to the first period
              return `
				 **${wikiResult.title}**
				${wikiResult.url}
			  `
                .trim()
                .replace(/\n\s+/g, '\n');
            })
            .join('\n\n');
        }
        return '';
      })
      .filter(Boolean)
      .join('\n\n');
  }
  calculateRowHeight(content: string | any): number {
    if (!content) return 20;
    const length =
      typeof content === 'string'
        ? content.length
        : JSON.stringify(content).length;
    const baseHeight = 20;
    const multiplier = 1.5; // Adjust this multiplier based on your preference
    return baseHeight + (length / 50) * baseHeight * multiplier;
  }

  downloadDOption() {
    this.downloadOptions = !this.downloadOptions;
  }

  downloadMOption() {
    this.downloadPew = !this.downloadPew;
  }

  viewMTabs() {
    this.showMCategory = !this.showMCategory;
  }

  closeMDownload() {
    this.downloadPew = false;
    this.showMCategory = false;
  }

  closeDDownload() {
    this.downloadOptions = false;
  }

  gridChange() {
    this.rightPanelAction = !this.rightPanelAction;
  }
  boldText(text: string): string {
    return `**${text}**`;
  }

  downloadAsPDF() {
    this.closeDDownload();
    $('.result-sec').addClass('download_pdf_init');

    // Store the original transform value
    let originalTransform = document.body.style.transform;

    // Set transform to scale(1) for 100% zoom
    document.body.style.transform = 'scale(1)';
    document.body.style.transformOrigin = '0 0'; // Ensures scaling happens from the top-left corner

    // Add fixed dimensions and positioning to all relevant elements
    let displayContentSections = document.getElementsByClassName(
      'display-content-section'
    );
    let originalStyles = [];

    for (let i = 0; i < displayContentSections.length; i++) {
      let element = displayContentSections[i] as HTMLElement; // Cast to HTMLElement
      originalStyles.push({
        element: element,
        styles: {
          width: element.style.width,
          height: element.style.height,
          display: element.style.display,
          position: element.style.position,
          float: element.style.float,
          clear: element.style.clear,
          top: element.style.top,
          left: element.style.left,
        },
      });
      element.style.width = element.offsetWidth + 'px';
      element.style.height = element.offsetHeight + 'px';
      element.style.display = 'block'; // Ensure it remains as a block element
      element.style.position = 'relative'; // Ensure relative positioning
      element.style.float = 'none'; // Prevent floating
      element.style.clear = 'both'; // Prevent any floating elements from affecting it
      element.style.top = '0';
      element.style.left = '0';
    }

    setTimeout(() => {
      let className = 'download_pdf_init';
      let divElement: any = document.getElementsByClassName(className)[0];
      let mainDashboardPosition = divElement.offsetLeft;
      let HTML_Width = divElement.offsetWidth;
      let HTML_Height = divElement.offsetHeight;
      divElement.scrollTop = HTML_Height;
      let top_left_margin = 15;

      // Use a fixed scale to handle zoom levels
      let scale = 1;

      let PDF_Width = HTML_Width * scale + top_left_margin * 2;
      let PDF_Height = HTML_Height * scale + top_left_margin * 2;

      let that = this;
      html2canvas(divElement, {
        scale: scale, // Use the scale to handle zoom levels
        useCORS: true,
      })
        .then(function (canvas) {
          document.getElementsByClassName(className)[0]['style']['left'] =
            mainDashboardPosition + 'px';
          let imgData = canvas.toDataURL('image/jpeg', 1.0);
          let pdf = new jsPDF({
            orientation: 'p',
            unit: 'px',
            format: [PDF_Width, PDF_Height],
            compress: true,
          });
          pdf.addImage(
            imgData,
            'JPG',
            top_left_margin,
            top_left_margin,
            HTML_Width * scale,
            HTML_Height * scale
          );
          pdf.save(that.searchQuery + '.pdf');
          $('.result-sec').removeClass('download_pdf_init');

          // Revert transform to original value
          document.body.style.transform = originalTransform;

          // Revert styles to original
          for (let i = 0; i < originalStyles.length; i++) {
            let { element, styles } = originalStyles[i];
            element.style.width = styles.width;
            element.style.height = styles.height;
            element.style.display = styles.display;
            element.style.position = styles.position;
            element.style.float = styles.float;
            element.style.clear = styles.clear;
            element.style.top = styles.top;
            element.style.left = styles.left;
          }
        })
        .catch(function (error) {
          console.log('error while generating CANVAS image');
          console.log(error, error.stack);

          // Revert transform to original value in case of error
          document.body.style.transform = originalTransform;

          // Revert styles to original in case of error
          for (let i = 0; i < originalStyles.length; i++) {
            let { element, styles } = originalStyles[i];
            element.style.width = styles.width;
            element.style.height = styles.height;
            element.style.display = styles.display;
            element.style.position = styles.position;
            element.style.float = styles.float;
            element.style.clear = styles.clear;
            element.style.top = styles.top;
            element.style.left = styles.left;
          }
        });
    }, 200);
  }

  downloadAsExcel() {
    this.generateExcel(this.searchResponse);
  }

  openToaster(isSuccess: boolean, isError: boolean, message: string) {
    if (isSuccess) {
      this.showSuccessToaster = true;
      this.showSuccessMsg = message;
    } else if (isError) {
      this.showErrorToaster = true;
      this.showErrorMsg = message;
    }

    setTimeout(() => {
      this.closeToaster();
    }, 10000);
  }

  closeToaster() {
    this.showErrorToaster = false;
    this.showErrorMsg = '';
    this.showSuccessToaster = false;
    this.showSuccessMsg = '';
  }

  // search bar related code

  ngAfterViewInit() {
    this.myForm.valueChanges.subscribe((value) => {
      if (this.desktopSearch) {
        this.desktopSearch.nativeElement.style.height = 'auto';
        this.desktopSearch.nativeElement.style.height = `${this.desktopSearch.nativeElement.scrollHeight}px`;
      }
    });
    this.mForm.valueChanges.subscribe((value) => {
      if (this.mobileSearch) {
        this.mobileSearch.nativeElement.style.height = 'auto';
        this.mobileSearch.nativeElement.style.height = `${this.mobileSearch.nativeElement.scrollHeight}px`;
      }
    });
  }

  closeHeaderSearch() {
    this.desktopSearch.nativeElement.style.height = 'auto';
    this.resizeSearch = false;
  }

  closeMobileSearch() {
    this.mobileSearch.nativeElement.style.height = 'auto';
    this.resizeMSearch = false;
  }

  resizerMSearch() {
    this.mobileSearch.nativeElement.style.height = `${this.mobileSearch.nativeElement.scrollHeight}px`;
    this.resizeMSearch = !this.resizeMSearch;
  }

  resizerSearchHeight() {
    this.desktopSearch.nativeElement.style.height = `${this.desktopSearch.nativeElement.scrollHeight}px`;
    this.resizeSearch = true;
  }
  redirectToResult() {
    if (!this.planTypeFlag) {
      this.router.navigate(['/'], {});
    } else {
      const docId = localStorage.getItem('docId');
      this.router.navigate(['/search-results-view'], {
        queryParams: { assertion: docId },
      });
    }
  }
}

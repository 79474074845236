<div *ngIf="isVisible" class="tutorial-modal-overlay">
    <div class="tutorial-modal">
      <div class="tutorial-header">
        <h2>{{ stepTitle }}</h2>
      </div>
      <div class="tutorial-body">
        <p>{{ stepContent }}</p>
      </div>
      <div class="tutorial-footer">
        <!-- Previous button (conditionally shown) -->
        <button *ngIf="currentStepIndex > 0" (click)="previousStep()">Previous</button>
        <!-- Next button -->
        <button *ngIf="currentStepIndex >= 0" (click)="nextStep()">Next</button>
        <!-- Close button -->
        <button (click)="closeTutorial()">Close</button>
      </div>
    </div>
  
    <!-- Highlight the current element -->
    <div *ngIf="highlightPosition" class="highlight-box"
      [ngStyle]="{
        top: highlightPosition.top + 'px',
        left: highlightPosition.left + 'px',
        width: highlightPosition.width + 'px',
        height: highlightPosition.height + 'px'
      }"></div>
  </div>
<!-- Mat loader starts -->
<div class="page_loader" *ngIf="isLoading">
  <!-- <mat-spinner></mat-spinner> -->
  <div class="text-loader">
    <img src="../../assets/images/svg/logo-icon.svg" alt="logo icon" />
    <div class="word"></div>
  </div>
</div>
<!-- Mat loader starts -->

<!-- warning toast starts -->
<div class="toast error static-toaster" *ngIf="showErrorToaster">
  <div class="outer-container">
    <img src="../../assets/images/svg/warning-icon.svg" alt="warning toaster" />
  </div>
  <div class="inner-container">
    <h3>Error</h3>
    <p>{{ showErrorMsg }}</p>
  </div>
  <a (click)="closeToaster()"
    ><img src="../../assets/images/svg/Cancel.svg" alt="close alert"
  /></a>
</div>
<div class="toast success static-toaster" *ngIf="showSuccessToaster">
  <div class="outer-container">
    <img
      src="../../assets/images/svg/success-toaster-icon.svg"
      alt="warning toaster"
    />
  </div>
  <div class="inner-container">
    <h3>Success</h3>
    <p>{{ showSuccessMsg }}</p>
  </div>
  <a (click)="closeToaster()"
    ><img src="../../assets/images/svg/Cancel.svg" alt="close alert"
  /></a>
</div>
<!-- warning toast ends -->

<div>
  <div class="left-menu-section" [ngClass]="showLeftPanel ? 'showh' : 'hideh'">
    <div class="m-close">
      <a (click)="hideLefthistory()"
        ><img src="../../assets/images/svg/Cancel.svg" alt="cancel icon"
      /></a>
    </div>
    <div class="tqlls_logo_block">
      <div class="authen_logo">
        <!-- <a href="/"><img src="../../assets/images/svg/authentification-logo.svg" alt="authentification logo"></a> -->
        <a href="/"
          ><img
            src="../../assets/images/left-filter-panel-logo.PNG"
            alt="authentification logo"
        /></a>
      </div>
    </div>

    <div class="tqlls_menu_block">
      <div
        class="inner_tq_menu"
        [ngClass]="{ show: !showHistoryMenu, hide: showHistoryMenu }"
      >
        <div class="panel-title" (click)="showHistory()">
          <h3>History</h3>
          <em class="down_arrow"></em>
        </div>
        <div class="scroll-search">
          <ul class="left-menu">
            <li
              class="nav-item clearfix active"
              *ngFor="let child of menuList; let i = index"
              routerLinkActive="is-active"
              [routerLinkActiveOptions]="{ exact: true }"
              [routerLink]="['/query']"
              [queryParams]="{ search: child.route }"
              [ngClass]="{
                active: isActive('sub', child.label),
                subnav: child.subChild
              }"
              (click)="select('sub', child.label, $event, 'redirect')"
            >
              <a title="{{ child.label }}"
                >{{ child.label | slice : 0 : 24
                }}{{ (child.label + "").length > 24 ? "..." : "" }}</a
              >
              <span class="dlt_action" *ngIf="!freeTrial && !child.hideDelete">
                <img
                  src="../../assets/images/svg/delete_icon.svg"
                  alt="delete"
                  (click)="deleteHistoryList(i, $event)"
                  title="Delete"
                />
              </span>
              <div
                class="dlt_multi_action"
                *ngIf="child.showActions && planTypeFlag"
              >
                <ul>
                  <li>
                    <a class="ok" (click)="deleteFile(child)">
                      <img
                        src="../../assets/images/svg/tick_icon.svg"
                        alt="tick icon"
                        title="Ok"
                    /></a>
                  </li>
                  <li>
                    <a class="cancel" (click)="deleteHistoryList(i, $event)">
                      <img
                        src="../../assets/images/svg/cross_icon.svg"
                        alt="cross icon"
                        title="Cancel"
                    /></a>
                  </li>
                </ul>
              </div>
              <div
                class="dlt_multi_action"
                *ngIf="child.showActions && !planTypeFlag"
              >
                <ul>
                  <li>
                    <a
                      class="ok"
                      (click)="removeMenuList(child.label, i, $event)"
                    >
                      <img
                        src="../../assets/images/svg/tick_icon.svg"
                        alt="tick icon"
                        title="Ok"
                    /></a>
                  </li>
                  <li>
                    <a class="cancel" (click)="deleteHistoryList(i, $event)">
                      <img
                        src="../../assets/images/svg/cross_icon.svg"
                        alt="cross icon"
                        title="Cancel"
                    /></a>
                  </li>
                </ul>
              </div>
            </li>
            <!-- <li class="nav-item clearfix" routerLinkActive="is-active"
                            [routerLinkActiveOptions]="{exact: true}">
                            <a >The Earth is round the Earth is round</a>
                            <span class="dlt_action" *ngIf="!hideDelete">
                                <img src="../../assets/images/svg/delete_icon.svg" alt="delete"
                                    (click)="deleteHistoryList()" title="Delete">
                            </span>
                            <div class="dlt_multi_action" *ngIf="showActions">
                                <ul>
                                    <li><a  class="ok">
                                            <img src="../../assets/images/svg/tick_icon.svg" alt="tick icon"
                                                title="Ok"></a>
                                    </li>
                                    <li><a  class="cancel" (click)="deleteHistoryList()">
                                            <img src="../../assets/images/svg/cross_icon.svg" alt="cross icon"
                                                title="Cancel"></a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li class="nav-item clearfix" routerLinkActive="is-active"
                            [routerLinkActiveOptions]="{exact: true}">
                            <a >The Earth is round the Earth is round</a>
                            <span class="dlt_action" *ngIf="!hideDelete">
                                <img src="../../assets/images/svg/delete_icon.svg" alt="delete"
                                    (click)="deleteHistoryList()" title="Delete">
                            </span>
                            <div class="dlt_multi_action" *ngIf="showActions">
                                <ul>
                                    <li><a  class="ok">
                                            <img src="../../assets/images/svg/tick_icon.svg" alt="tick icon"
                                                title="Ok"></a>
                                    </li>
                                    <li><a  class="cancel" (click)="deleteHistoryList()">
                                            <img src="../../assets/images/svg/cross_icon.svg" alt="cross icon"
                                                title="Cancel"></a>
                                    </li>
                                </ul>
                            </div>
                        </li> -->
          </ul>
        </div>
      </div>
    </div>

    <div class="history_footer" style="display: none">
      <div class="upgrade-plan" *ngIf="freeTrial">
        <img src="../../assets/images/Guarantee.png" alt="guarantee img" />
        <div class="upgrade-dtl">
          <h3>Upgrade Plan</h3>
          <p>Free trial: {{ freeTrialDay }} days left</p>
        </div>
      </div>
      <div class="footer-user-details">
        <div class="user-img" *ngIf="userData.profileImage">
          <img
            [src]="
              userData.profileImage
                ? userData.profileImage
                : '../../assets/images/user.png'
            "
            alt="user image"
          />
        </div>
        <div class="user-details">
          <h3>
            {{ userData.name | slice : 0 : 14 | titlecase
            }}{{ (userData.name + "").length > 14 ? "..." : "" }}
          </h3>
          <p>
            {{ userData.email | slice : 0 : 18
            }}{{ (userData.email + "").length > 18 ? "..." : "" }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="truth-meter-section">
  <h3>
    Truth Meter
    <span
      class="label_text"
      *ngIf="
        labelText &&
        !ranking &&
        env === 'dev-portal' &&
        planType != 'Legit™ Lite'
      "
      >{{ labelText }}</span
    >
  </h3>
  <!-- <div class="t-meter-val">
        <h3>{{truthMeter}}</h3>
    </div> -->
  <ng-container>
    <div class="trust-guage-section" [attr.data-truth-meter-score]="truthMeter">
      <!-- <div class="trust-tooltip">
                <p>The Truth Meter provides a visual gauge indicating the likelihood that the assertion is accurate. It ranges from 1% to 100%, with options such as "Very Unlikely," "Unlikely," "Partially True," "Likely," and "Very Likely" to help users assess the credibility of the information. Note that, the meter value is evaluated based on multiple parameters and we would suggest you to view the URLs below for more information.</p>
            </div> -->
      <rg-gauge-chart
        [canvasWidth]="canvasWidth"
        [needleValue]="needleValue"
        [centralLabel]="centralLabel"
        [options]="options"
      >
      </rg-gauge-chart>
      <h4>{{ graphType }}</h4>
    </div>
  </ng-container>
</div>

<!-- Mat loader starts -->
<div class="page_loader" *ngIf="isLoading">
    <!-- <mat-spinner></mat-spinner> -->
    <div class="text-loader">
        <img src="../../assets/images/svg/logo-icon.svg" alt="logo icon" />
        <div class="word"></div>
    </div>
</div>
<!-- Mat loader starts -->

<!-- warning toast starts -->
<div class="toast error static-toaster" *ngIf="showErrorToaster">
    <div class="outer-container">
        <img src="../../assets/images/svg/warning-icon.svg" alt="warning toaster">
    </div>
    <div class="inner-container">
        <h3>Error</h3>
        <p>{{showErrorMsg}}</p>
    </div>
    <a  (click)="closeToaster()"><img src="../../assets/images/svg/Cancel.svg" alt="close alert" /></a>
</div>
<div class="toast success static-toaster" *ngIf="showSuccessToaster">
    <div class="outer-container">
        <img src="../../assets/images/svg/success-toaster-icon.svg" alt="warning toaster">
    </div>
    <div class="inner-container">
        <h3>Success</h3>
        <p>{{showSuccessMsg}}</p>
    </div>
    <a  (click)="closeToaster()"><img src="../../assets/images/svg/Cancel.svg" alt="close alert" /></a>
</div>
<!-- warning toast ends -->

<div class="citation-header">
    <div class="history-menu-icon">
        <a  (click)="leftMenuAction()"><img src="../../../assets/images/svg/menu-icon.svg"
                alt="menu icon" /></a>
    </div>
</div>

<div class="middel_content_section cita-section">
    <div class="cita-header clearfix">
        <div class="hhs-title">
            <h3 class="clearfix">
                Citations
                <div class="info_dropdown">
                    <a class="info-icon"></a>
                    <ul>
                        <li><p>Citation page provides a list of citations found based on the selection made on the search results page.</p></li>
                    </ul>
                </div>
                <span class="go-back" (click)="redirectBack()"><img src="../../assets/images/svg/back-button.svg" alt="back button" title="Back to page"/><em>Back to Page</em></span>
            </h3>
        </div>

        <!-- <div class="rht-tts-title"> -->
            <!-- <div class="added-date">
                Date added <span>02.16.2023</span>
            </div> -->
            <!-- <div class="search-citation">
                <input type="text" placeholder="Search" name="searchcita">
            </div> -->
        <!-- </div> -->
    </div>

    <div class="eco_filter_section clearfix">
        <div class="eco-select-all">
            <!-- <mat-checkbox></mat-checkbox> <span>Sources <em>({{totalSources}})</em></span> -->
            <span>Sources <em>({{totalSources}})</em></span>
        </div>
        <!-- <div class="cita_right_filters">
            <ul>
                <li class="citation-format">
                    <div class="cita_format clearfix">
                        <h3>Citation Format</h3>
                        <div class="sort-country">
                            <mat-form-field appearance="fill">
                                <mat-label>Country</mat-label>
                                <mat-select>
                                    <mat-option *ngFor="let country of countries" [value]="country.value">
                                        {{country.viewValue}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </li>
                <li class="sort_option">
                    <div class="cita_format clearfix">
                        <h3>Sort</h3>
                        <div class="sort-country">
                            <mat-form-field appearance="fill">
                                <mat-label>All</mat-label>
                                <mat-select>
                                    <mat-option *ngFor="let sort of sorts" [value]="sort.value">
                                        {{sort.viewValue}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </li>
                <li class="group_option">
                    <div class="cita_format clearfix">
                        <h3>Group</h3>
                        <div class="sort-country">
                            <mat-form-field appearance="fill">
                                <mat-label>No</mat-label>
                                <mat-select>
                                    <mat-option *ngFor="let gsort of gsorts" [value]="gsort.value">
                                        {{gsort.viewValue}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </li>
            </ul>
        </div> -->
    </div>

    <!-- <div class="cita_listing_section">
        <ul>
            <li *ngFor="let citation of citationsData">
                <div class="cita_in_list clearfix">
                    <mat-checkbox [value]="citation.data.cid" (change)="onCheckboxChange($event, citation.data.cid)"></mat-checkbox>
                    <p><span>Full Citation:</span> {{citation.data.last}} {{citation.data.first ? citation.data.first +
                        '.' : ''}}
                        {{citation.data['access-date'] ? '(' + citation.data['access-date'] + ').' : ''}}
                        {{citation.data.title ? citation.data.title + '.' : ''}} {{citation.data.url ? citation.data.url
                        : ''}}</p>
                </div>
            </li>
        </ul>
        <mat-paginator #paginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [showFirstLastButtons]="true"
            [length]="length" [pageIndex]="pageIndex" (page)="pageEvent = handlePage($event)">
        </mat-paginator>
    </div> -->

    <div class="cita_listing_section">
        <ul>
            <li *ngFor="let citation of citationsData">
                <div class="cita_in_list clearfix">
                    <mat-checkbox [value]="citation.id" (change)="onCheckboxChange($event, citation.id)"></mat-checkbox>
                    <p (click)="openInNewWindow(citation.id)" [innerHTML]="citation.citation" style="cursor: pointer;"></p>
                </div>
            </li>
        </ul>
        <div class="pagination" *ngIf="citationsData.length > 10">
        <mat-paginator #paginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [showFirstLastButtons]="true"
            [length]="length" [pageIndex]="pageIndex" (page)="pageEvent = handlePage($event)">
        </mat-paginator>
        </div>
    </div>

</div>

<div class="citation_footer_section">
    <ul>
        <li>
            <div class="copy-citation"><button (click)="copyCitations()">Copy All</button></div>
        </li>
        <li>
            <div class="export-citation"><button (click)="downloadCSVFile()">Export</button></div>
        </li>
    </ul>
</div>
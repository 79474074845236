import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TutorialService } from '../services/tutorial.service';

@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.scss'],
})
export class TutorialComponent implements OnInit {
  @Input() stepContent: string = '';
  @Input() stepTitle: string = '';
  @Input() isVisible: boolean = false;
  currentStepIndex = 0;
  totalSteps: number = 0;
  highlightPosition: { top: number, left: number, width: number, height: number } | null = null;

  constructor(private tutorialService: TutorialService, private router: Router) {}

  nextStep() {
    const next = this.tutorialService.nextStep();
    this.updateTutorial(next);
  }

  previousStep() {
    const previous = this.tutorialService.previousStep();
    this.updateTutorial(previous);
  }

  closeTutorial() {
    this.tutorialService.endTutorial();
  }

  updateTutorial(step: { content: string; title: string; route: string }) {
    this.stepContent = step.content;
    this.stepTitle = step.title;
    this.currentStepIndex = this.tutorialService.getCurrentStepIndex();
    this.totalSteps = this.tutorialService.getSteps().length;
    this.setHighlightPosition(step.route);
  }

  setHighlightPosition(route: string | undefined) {
    if (!route) return;
  
    const elements = document.querySelectorAll(`.${route}`);
    console.log('Elements:', elements);
  
    if (elements.length > 0) {
      elements.forEach((element) => {
        const rect = element.getBoundingClientRect();
        console.log('Element Rect:', rect);
  
        this.highlightPosition = {
          top: rect.top + window.scrollY,
          left: rect.left + window.scrollX,
          width: rect.width,
          height: rect.height,
        };
  
        
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  
        
        element.classList.add('highlight');
  
        
        setTimeout(() => {
          element.classList.remove('highlight');
        }, 3000);
      });
    } else {
      this.highlightPosition = null;
    }
  }
  

  ngOnInit() {
    const step = this.tutorialService.getCurrentStep();
    this.updateTutorial(step);
  }
}

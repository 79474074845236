import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  NgZone,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { UserService } from '../services/user.service';
import { MatDialog } from '@angular/material/dialog';
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { GlobalConfirmationPopupComponent } from '../global-confirmation-popup/global-confirmation-popup.component';

import { TutorialService } from '../services/tutorial.service';

import { OwlOptions } from 'ngx-owl-carousel-o';
import { format } from 'path';
import { MatIconModule } from '@angular/material/icon';
declare var webkitSpeechRecognition;

// declare var searchLoader: any;
declare var $: any;

@Component({
  selector: 'app-new-global-search',
  templateUrl: './new-global-search.component.html',
  styleUrls: ['./new-global-search.component.scss'],
  styles: [
    `
      textarea {
        resize: none;
        overflow: hidden;
      }
    `,
  ],
})
export class NewGlobalSearchComponent implements OnInit {
  @Output() getSuggestions = new EventEmitter<void>();

  historySlider: OwlOptions = {
    loop: false,
    margin: 20,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    autoWidth: true,
    navSpeed: 700,
    // rewind: true,
    slideBy: 2,
    mergeFit: true,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 5,
      },
      1100: {
        items: 6,
      },
    },
    nav: true,
  };

  @ViewChild('searchText') searchText: ElementRef;
  myForm: FormGroup;
  rows: number = 1;
  email: AbstractControl;

  // Loader & Error Handling Variables
  isLoading: boolean = false;
  showSuccessToaster: boolean = false;
  isListen: boolean = false;
  showSuccessMsg: string = '';
  showErrorToaster: boolean = false;
  showErrorMsg: string = '';

  // App Dataflow Variables
  searchQuery = '';
  userId: string;
  userName: string;
  userHistory: any = [];
  productVersion: any = '';
  desktopUserHistory: any = [];
  searchSuggestions: any = [];
  private timeout: any;
  containerHeight: string = 'auto';
  placeholderText: string = '';
  fileToUpload: any;
  imageUrl: any;
  results: any = '';
  inputTimer: number = 0;
  giveSuggestions: boolean = true;
  isAssertionFocused: boolean = false;

  file: File | null = null;
  fileContent: string | ArrayBuffer | BlobPart | null = '';
  arrTemp = [
    { format: 'doc', size: '10MB' },
    { format: 'docx', size: '10MB' },
    { format: 'txt', size: '10MB' },
    { format: 'mp4', size: '50MB' },
    { format: 'mp3', size: '30MB' },
  ];

  historyLoaderArray = new Array(6);

  formatsArray = this.arrTemp.map((value) => value.format);

  fileDocuments: any = [];
  fileUploadLoader: boolean = false;
  planType: string = '';
  planTypeFlag: boolean = false;

  highlightTutorialStep: boolean = false;

  constructor(
    private router: Router,
    public userService: UserService,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private ngZone: NgZone,
    private tutorialService: TutorialService
  ) {
    this.userService.userID$.subscribe((userId: string) => {
      if (userId) {
        this.userId = userId;
        this.getUserDetails(this.userId);
      } else if (
        localStorage.getItem('userId') &&
        localStorage.getItem('userId') != null
      ) {
        this.userId = localStorage.getItem('userId');
        this.getUserDetails(this.userId);
      } else {
        this.openToaster(false, true, 'Unauthorized Access');
        this.router.navigate(['/unauthorized']);
      }
    });

    this.userService.appLoading$.subscribe((appLoad) => {
      if (appLoad) {
        this.isLoading = true;
      } else {
        this.isLoading = false;
      }
    });

    // Form Builder
    this.myForm = fb.group({
      email: [
        '',
        Validators.compose([Validators.required, Validators.minLength(4)]),
      ],
    });
    this.email = this.myForm.controls['email'];
    this.userService.getVersion().subscribe((Resp: any) => {
      if (Resp.meta.code == 200) {
        this.productVersion = Resp.data.version_number
          ? 'v' + Resp.data.version_number
          : 'v2.0.8.8.23112012';
      } else {
        this.productVersion = 'v2.0.8.8.23112012';
      }
    });
  }

  ngOnInit(): void {
    var words = ['Please wait for results…', 'Please wait for results…'],
      part,
      i = 0,
      offset = 0,
      len = words.length,
      forwards = true,
      skip_count = 0,
      skip_delay = 15,
      speed = 70;

    var wordflick = function () {
      setInterval(function () {
        if (forwards) {
          if (offset >= words[i].length) {
            ++skip_count;
            if (skip_count == skip_delay) {
              forwards = false;
              skip_count = 0;
            }
          }
        } else {
          if (offset == 0) {
            forwards = true;
            i++;
            offset = 0;
            if (i >= len) {
              i = 0;
            }
          }
        }
        part = words[i].substr(0, offset);
        if (skip_count == 0) {
          if (forwards) {
            offset++;
          } else {
            offset--;
          }
        }
        $('.word').text(part);
      }, speed);
    };

    $(document).ready(function () {
      wordflick();
    });

    setTimeout(() => {
      this.historySliderCode();
    }, 1500);

    // const currentStep = this.tutorialService.getCurrentStep();
    // this.highlightTutorialStep = currentStep === 'main-page';

    if (this.highlightTutorialStep) {
      console.log('Highlight the main page step!');
    }
  }

  nextStep() {
    this.tutorialService.nextStep();
    // Add any logic to handle subsequent steps
  }

  historySliderCode() {
    setTimeout(() => {
      // $('.history-slider').slick({
      // 	slidesToShow: 6,
      // 	dots: false,
      // 	autoplay: false,
      // 	arrows: true,
      // 	infinite: false,
      // 	speed: 500,
      // 	centerMode: false,
      // 	variableWidth: false,
      // 	draggable: false,
      // 	responsive: [{
      // 		breakpoint: 1500,
      // 		settings: {
      // 			slidesToShow: 3,
      // 			slidesToScroll: 1,
      // 		}
      // 	}, {
      // 		breakpoint: 1024,
      // 		settings: {
      // 			slidesToShow: 2,
      // 			slidesToScroll: 1,
      // 		}
      // 	}, {
      // 		breakpoint: 800,
      // 		settings: {
      // 			slidesToShow: 2,
      // 			slidesToScroll: 2,
      // 			infinite: false,
      // 		}
      // 	}, {
      // 		breakpoint: 600,
      // 		settings: {
      // 			slidesToShow: 1,
      // 			slidesToScroll: 1,
      // 			infinite: true,
      // 		}
      // 	}, {
      // 		breakpoint: 480,
      // 		settings: {
      // 			slidesToShow: 1,
      // 			slidesToScroll: 1,
      // 			infinite: true,
      // 		}
      // 	}, {
      // 		breakpoint: 320,
      // 		settings: {
      // 			slidesToShow: 1,
      // 			slidesToScroll: 1,
      // 			infinite: true,
      // 		}
      // 	}]
      // })
    }, 1000);
  }

  ngAfterViewInit() {
    this.myForm.valueChanges.subscribe((value) => {
      if (this.searchText?.nativeElement) {
        this.searchText.nativeElement.style.height = 'auto';
        this.searchText.nativeElement.style.height = `${this.searchText.nativeElement.scrollHeight}px`;
      }
    });
  }

  removeSearch() {
    this.searchQuery = '';
    const inputField = document.querySelector(
      'input[name="searchQuery"]'
    ) as HTMLInputElement;
    if (inputField) {
      inputField.focus();
    }
    this.searchText.nativeElement.style.height = 'auto';
  }
  onChange(event: any, key: any) {
    this.isAssertionFocused = true;
    // if (this.isListen) {
    if (key === 'search_query') {
      let value = event.target.value ? event.target.value.trim() : '';
      if (value.length > 0) {
        this.searchQuery = value.charAt(0).toUpperCase() + value.slice(1);
      } else {
        this.searchQuery = '';
      }
      // this.isListen = false;
      // }
    }
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.getSearchSuggestions();
    }, 1000);
  }

  preventEnterKey(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.createAnalysis();
    }
  }

  createAnalysis(): void {
    // this.isLoading = true;
    this.searchSuggestions = [];
    let userId = localStorage.getItem('userId');
    let token = localStorage.getItem('access_token');

    if (!this.searchQuery) {
      this.openToaster(false, true, 'Please provide an assertion in the text box to proceed further.');
      setTimeout(() => {
        this.closeToaster();
      }, 5000);
      return;
    }


    // let searchCount = 0;

    // this.userService.getUserHistoryCount(userId, token).subscribe((Resp: any) => {
    // 	if (Resp.meta.code == 200) {
    // 		// searchCount = Resp.data.count;
    // 		this.performAnalysis(token, userId);
    // 	} else {
    // 		this.performAnalysis(token, userId);
    // 	}
    // }, (err: any) => {
    // 	console.log(err);
    // });

    this.fileUploadLoader = true;
    if (!this.planTypeFlag) {
      this.performAnalysis(token, userId);
    } else {
      if (this.searchQuery === '') {
        return;
      }
      this.userService
        .uploadPlainTextData(this.searchQuery, userId, token)
        .subscribe(
          (Response: any) => {
            if (Response.meta.fileId) {
              if (!this.planTypeFlag) {
                this.performAnalysis(token, userId);
              }

              const processingMode = Response.meta.processingMode;

              this.userService
                .sendPostData(Response.meta.fileId, userId)
                .subscribe((Resp: any) => {
                  if (this.planTypeFlag) {
                    this.fileUploadLoader = false;
                  }
                  if (!this.planTypeFlag) {
                    this.getUserHistory();
                  } else {
                    if (processingMode === 'sync') {
                      this.router.navigate(['/search-results-view'], {
                        queryParams: { assertion: Response.meta.fileId },
                      });
                    } else {
                      this.openToaster(
                        true,
                        false,
                        'Please check history for results.'
                      );
                      this.getUserDocuments();
                    }
                  }
                });
            } else {
              this.openToaster(false, true, 'Failed to upload text document.');
            }
          },
          (error: any) => {
            console.log(error);
            this.openToaster(false, true, 'Failed to upload text document.');
          }
        );
    }
  }

  performAnalysis(token, userId) {
    if (this.searchQuery === '') {
      return;
    }
    sessionStorage.setItem('searchQuery', this.searchQuery);
    if (this.getTheWordCount(this.searchQuery) > 2) {
      this.searchQuery = this.searchQuery.trim();

      this.userService
        .searchAnalysis(this.searchQuery, token, userId)
        .subscribe(
          (Response: any) => {
            console.log(Response);
            if (Response.resultCode == 0) {
              setTimeout(() => {
                this.fileUploadLoader = false;
                this.dialog.open(GlobalConfirmationPopupComponent, {
                  disableClose: true,
                });
              }, 200);
            } else if (Response.query && Response.resultCode == 1) {
              if (
                Response?.searchBoxAdditionalInfo?.additionalString &&
                Response?.searchBoxAdditionalInfo?.additionalString != null
              ) {
                setTimeout(() => {
                  this.fileUploadLoader = false;
                  this.dialog.open(GlobalConfirmationPopupComponent, {
                    disableClose: true,
                  });
                }, 200);
              } else {
                this.saveHistoryNavigateToQuery(userId, token);
              }
            } else {
              this.userService.sendErrorEmailReport(token);
              this.openToaster(
                false,
                true,
                'Failed to retrieve search results for "' +
                  this.searchQuery +
                  '". Server is currently busy, please try again in some time.'
              );
            }
          },
          (error: any) => {
            this.fileUploadLoader = false;
            this.userService.sendErrorEmailReport(token);
            this.openToaster(
              false,
              true,
              'Failed to retrieve search results for "' +
                this.searchQuery +
                '". Server is currently busy, please try again in some time.'
            );
          }
        );
    } else {
      this.fileUploadLoader = false;
      this.openToaster(
        false,
        true,
        'Please enter atleast 3 or more words to test the assertion.'
      );
    }
  }

  getTheWordCount(inputText: string) {
    inputText = inputText.trim();

    // Split the input text into an array of words
    var words = inputText.split(/\s+/);

    // Return the number of words
    return words.length;
  }

  saveHistoryNavigateToQuery(userId: string, token: string): void {
    this.userService
      .saveSearchHistory(this.searchQuery, userId, token)
      .subscribe(
        (Resp: any) => {
          this.router.navigate(['/query'], {
            queryParams: { search: this.searchQuery },
          });
          this.isLoading = false;
          console.log(Resp);
        },
        (error: any) => {
          this.openToaster(false, true, 'Failed to retrieve search analysis.');
          console.log(error);
        }
      );
  }

  getSearchSuggestions() {
    let token = localStorage.getItem('access_token');
    this.userService.getSearchSuggestions(token, this.searchQuery).subscribe(
      (Response: any) => {
        if (Response.meta.code == 200) {
          this.searchSuggestions = Response.data?.assertions;
          this.isListen = true;
        } else {
          // this.openToaster(false, true, "Failed to retrieve search suggestions.");
        }
      },
      (error: any) => {
        // this.openToaster(false, true, "Failed to retrieve search suggestions.");
        console.log(error);
      }
    );
  }

  getUserHistory(): void {
    let userId = localStorage.getItem('userId');
    let access_token = localStorage.getItem('access_token');
    this.userService.getHistory(userId, access_token).subscribe(
      (Response: any) => {
        if (Response.meta.code == 200) {
          this.userHistory = Array.from(
            new Set(Response.data.map((h: any) => h.searchKey))
          );
          // this.userHistory = Array.from(new Set(Response.data.map((h: any) => (h.searchKey))));
          // this.desktopUserHistory = this.userHistory.slice(0, 3);
        } else if (Response.meta.code == 1006) {
          console.log('No History Found');
          this.userHistory = [];
        } else {
          this.userHistory = [];
          // this.openToaster(false, true, 'Failed to retrieve user history data.');
        }
      },
      (error: any) => {
        this.openToaster(false, true, 'Failed to retrieve user history data.');
        console.log(error);
      }
    );
    console.log(this.userHistory);
  }

  setSearchQuery(query: any) {
    this.searchQuery = query;
    this.searchSuggestions = [];
    this.createAnalysis();
  }

  onOutsideClick() {
    this.searchSuggestions = [];
  }

  getUserDetails(id: string): void {
    let token = localStorage.getItem('access_token');
    this.userService
      .getSubscriptionDetails(id, token)
      .subscribe((Response: any) => {
        this.planType = Response.data[0].planName;

        if (this.planType == 'Legit™ Lite') {
          this.planTypeFlag = false;
        } else if (this.planType == 'Legit™ Premium') {
          this.planTypeFlag = true;
        } else if (this.planType == 'Legit™ Professional') {
          this.planTypeFlag = false;
        } else if (this.planType == 'Free Trial') {
          this.planTypeFlag = false;
        } else {
          this.planTypeFlag = false;
        }

        // Assertion Placeholder based on PlanType
        if (!this.planTypeFlag) {
          this.placeholderText = 'Write an assertion ...';
          this.getUserHistory();
        } else {
          this.placeholderText =
            'Write an assertion / sentence(s) / paragraph(s) ...';
          this.getUserDocuments();
        }
      });

    this.userService.getUserDetails(id).subscribe(
      (Response: any) => {
        this.isLoading = false;
        if (Response.meta.code == 200) {
          this.userName = localStorage.getItem('userName')
            ? localStorage.getItem('userName').split(' ')[0]
            : '';
          if (
            localStorage.getItem('userId') &&
            localStorage.getItem('access_token')
          ) {
            if (!this.planTypeFlag) {
              this.getUserHistory();
            } else {
              this.getUserDocuments();
            }
          }
        } else {
          this.openToaster(false, true, 'Unauthorized Access.');
          this.router.navigate(['/unauthorized']);
        }
      },
      (error: any) => {
        sessionStorage.removeItem('error-report');
        if (error?.error?.meta?.code == 1021) {
          sessionStorage.setItem(
            'error-report',
            JSON.stringify({
              heading: 'Free Trial Expired',
              message:
                'Your Free Trial is completed. Please purchase a plan to continue.',
            })
          );
          this.openToaster(
            false,
            true,
            'Your Free Trial is completed. Please purchase a plan to continue.'
          );
          this.router.navigate(['/unauthorized']);
        } else if (error?.error?.meta?.code == 1022) {
          sessionStorage.setItem(
            'error-report',
            JSON.stringify({
              heading: 'Subscription Expired',
              message:
                'Your Subscription is completed. Please purchase a plan to continue.',
            })
          );
          this.openToaster(
            false,
            true,
            'Your Subscription is completed. Please purchase a plan to continue.'
          );
          this.router.navigate(['/unauthorized']);
        } else {
          sessionStorage.setItem(
            'error-report',
            JSON.stringify({
              heading: 'Unauthorized Access',
              message:
                'This URL is valid but you are not authorized for this content.',
            })
          );
          this.openToaster(false, true, 'Unauthorized Access.');
          this.router.navigate(['/unauthorized']);
        }
        console.log(error);
      }
    );
  }

  refreshDocs() {
    if (!this.planTypeFlag) {
      this.getUserHistory();
      this.fileUploadLoader = true;
    } else {
      this.getUserDocuments();
    }

    let executionCount = 0; // Counter to track the number of executions
    const maxExecutions = 20; // Maximum allowed executions
  
    const intervalId = setInterval(() => {
      this.getUserDocuments();
  
      executionCount++;

      const latestDocuments = this.fileDocuments?.slice(0, 3);
  
      if (latestDocuments && latestDocuments.every((file: any) => file.progress === 100)) {
        console.log("All files are fully loaded.");
        clearInterval(intervalId);
        this.fileUploadLoader = false;
        this.historySliderCode();
      } else if (executionCount >= maxExecutions) {
        console.log("Maximum execution limit reached. Stopping refresh.");
        clearInterval(intervalId);
        this.fileUploadLoader = false;
      } else {
        console.log(`Execution ${executionCount}: Some files are still loading...`);
        latestDocuments?.forEach((doc, index) => 
          console.log(`Document ${index + 1}: Progress = ${doc.progress}%`)
        );
      }
    }, 3000);
  }

  getUserDocuments() {
    console.log("Executed.")
    this.fileUploadLoader = true;
    let userId = localStorage.getItem('userId');
    let access_token = localStorage.getItem('access_token');
    this.userService.getUserDocumentsByUser(userId, access_token).subscribe(
      (Response: any) => {
        if (Response.meta.files && Response.meta.files.length > 0) {
          this.fileDocuments = Response.meta.files.filter(
            (file: any) => file.status !== 'inactive' && file.status !== 'draft'
          );
          setTimeout(() => {
            this.fileUploadLoader = false;
            this.fileDocuments = Response.meta.files.filter(
              (file: any) =>
                file.status !== 'inactive' && file.status !== 'draft'
            );
          }, 1000);
          console.log(this.fileDocuments);
          
        } else if (Response.meta.code == 1006) {
          console.log('No Documents Found');
          this.openToaster(false, true, 'No Documents Found');
          this.fileUploadLoader = false;
          this.fileDocuments = [];
        } else {
          this.fileUploadLoader = false;
          this.fileDocuments = [];
          this.openToaster(
            false,
            true,
            'Failed to retrieve user history documents.'
          );
        }
      },
      (error: any) => {
        this.fileUploadLoader = false;
        this.openToaster(
          false,
          true,
          'Failed to retrieve user history documents data.'
        );
        console.log(error);
      }
    );
  }

  deleteFile(docId: any) {
    let userId = localStorage.getItem('userId');
    let access_token = localStorage.getItem('access_token');

    // Calling the deleteUserDocument method from userService
    this.userService.deleteUserDocument(userId, access_token, docId).subscribe(
      (response: any) => {
        if (response.meta.code === 200) {
          // Check planTypeFlag and call the appropriate method
          if (!this.planTypeFlag) {
            this.getUserHistory();
          } else {
            this.getUserDocuments();
          }

          // Open a success toaster
          this.openToaster(
            true,
            false,
            'History document deleted successfully.'
          );
        } else {
          this.openToaster(false, true, response.meta.message);
        }
        // Close the toaster after 5 seconds
        setTimeout(() => {
          this.closeToaster();
        }, 5000);
      },
      (error: any) => {
        console.log(error);
        this.openToaster(
          false,
          true,
          'Failed to delete user history document.'
        );
      }
    );
  }

  deleteHistoryQuery(doc: any) {
    let userId = localStorage.getItem('userId');
    let access_token = localStorage.getItem('access_token');
    this.userService.removeMenuHistory(userId, access_token, doc).subscribe(
      (Response: any) => {
        if (!this.planTypeFlag) {
          this.getUserHistory();
        } else {
          this.getUserDocuments();
        }
        this.openToaster(true, false, 'History document deleted successfully.');
        setTimeout(() => {
          this.closeToaster();
        }, 5000);
      },
      (error: any) => {
        console.log(error);
        this.openToaster(
          false,
          true,
          'Failed to delete user history document.'
        );
      }
    );
  }

  // Function to download .mp4 document
  downloadMp4Document(docId: string) {
    // Get the userId and access token from localStorage
    let userId = localStorage.getItem('userId');
    let access_token = localStorage.getItem('access_token');

    // Fetch the document details using getUserDocumentsById
    this.userService
      .getUserDocumentsById(userId, access_token, docId)
      .subscribe(
        (response: any) => {
          console.log('Document details:', response);
          // Assuming the response contains htmlFileUrl for downloading the document
          if (response && response.data && response.data.htmlFileUrl) {
            const fileUrl = response.data.htmlFileUrl;
            // Trigger download
            const link = document.createElement('a');
            link.href = fileUrl;
            link.target = '_blank'; // Open the link in a new tab
            link.download = fileUrl.split('/').pop(); // Set the file name from the URL
            link.click();
          } else {
            console.log('Failed to retrieve document URL for download.');
          }
        },
        (error: any) => {
          console.log('Error fetching document details:', error);
        }
      );
  }

  redirectToResult(docId: any, docStatus: any, doc: any) {
    if (!this.planTypeFlag) {
      // Redirect to query if planTypeFlag is false
      return this.router.navigate(['/query'], {
        queryParams: { search: doc.previewText },
      });
    }

    if (docStatus === 'processing') {
      // Show toaster if document is still processing
      return this.openToaster(
        false,
        true,
        'This document is processing at the moment. Please refresh the webpage after some time.'
      );
    }

    // Handle document based on type
    if (['.mp4', '.mp3'].includes(doc.type)) {
      this.downloadMp4Document(docId);
    } else {
      this.router.navigate(['/search-results-view'], {
        queryParams: { assertion: docId },
      });
    }
  }

  redirectToQuery(doc: any) {
    this.router.navigate(['/query'], { queryParams: { search: doc } });
  }

  openHistory(query: any) {
    this.searchQuery = query;
    this.createAnalysis();
  }

  openToaster(isSuccess: boolean, isError: boolean, message: string) {
    if (isSuccess) {
      this.showSuccessToaster = true;
      this.showSuccessMsg = message;
    } else if (isError) {
      this.showErrorToaster = true;
      this.showErrorMsg = message;
    }
  }

  closeToaster() {
    this.showErrorToaster = false;
    this.showErrorMsg = '';
    this.showSuccessToaster = false;
    this.showSuccessMsg = '';
  }

  // Upload files dummy script
  handleFileInput(file: FileList) {
    this.fileToUpload = file.item(0);

    let fileType: string = this.fileToUpload.name.split('.').pop() || '';
    const fileStatus = this.arrTemp.find((value) => value.format === fileType);
    console.log('fileStatus ::', fileStatus);
    if (!fileStatus) {
      this.openToaster(
        false,
        true,
        'File type not supported, Please upload one of the following file types: doc, docx, txt'
      );
      console.log(
        'File type not supported, Please upload one of the following file types: doc, docx, txt'
      );
      return;
    } else {
      const sizeValidation = this.validateSize(
        fileStatus,
        this.fileToUpload.size
      );

      if (!sizeValidation.isValid) {
        const fileType = fileStatus.format.toLowerCase();
        const typeMessage =
          fileType === 'doc' || fileType === 'docx' || fileType === 'txt'
            ? 'Uploaded file size should be less than '
            : fileType === 'mp4'
            ? 'Uploaded video file size should be less than '
            : fileType === 'mp3'
            ? 'Uploaded audio file size should be less than '
            : 'Uploaded file size should be less than ';
        this.openToaster(
          false,
          true,
          `${typeMessage}${sizeValidation.requiredSize}`
        );
        return;
      }
    }

    this.fileUploadLoader = true;
    this.userService
      .getPresizedURL(this.fileToUpload.name)
      .subscribe((Response: any) => {
        // console.log(Response);
        if (Response.httpCode == 200 && Response.meta.url) {
          let userId = localStorage.getItem('userId');
          let url = Response.meta.url;
          this.userService.sendAssertionFile(url, this.fileToUpload).subscribe(
            (response: any) => {
              console.log(response);
              this.userService
                .sendPostData(Response.meta.fileId, userId)
                .subscribe((Resp: any) => {
                  console.log(Resp);
                  this.fileUploadLoader = false;
                  if (!this.planTypeFlag) {
                    this.getUserHistory();
                  } else {
                    this.getUserDocuments();
                    this.refreshDocs();
                  }
                });
            },
            (error: any) => {
              // this.router.navigate(['/search-results-view']);
              this.openToaster(false, true, 'Error Uplaoding File');
              console.log(error);
              this.fileUploadLoader = false;
            }
          );
        } else {
          console.log('Handle Error case');
          this.openToaster(false, true, 'Error Uplaoding File');
        }
      });
  }

  validateSize(
    fileStatus: any,
    fileSize: number
  ): { isValid: boolean; requiredSize?: string } {
    let requiredSize: number;
    if (fileStatus) {
      requiredSize = Number(
        fileStatus.size.slice(0, fileStatus.size.length - 2)
      ); // Extract size in MB
      const fileSizeInMB = fileSize / (1000 * 1000); // Convert file size to MB
      if (requiredSize < fileSizeInMB) {
        return { isValid: false, requiredSize: fileStatus.size }; // Include the threshold size
      }
    }
    return { isValid: true }; // File size is valid
  }

  startListening() {
    this.isListen = true;
    if (navigator.permissions) {
      if ('webkitSpeechRecognition' in window) {
        const vSearch = new webkitSpeechRecognition();
        vSearch.continuous = true;
        vSearch.interimresults = true;
        vSearch.lang = 'en-US';
        vSearch.start();
        vSearch.onresult = (e) => {
          this.ngZone.run(() => {
            console.log(e);
            this.results = e.results[0][0].transcript;
            this.getResult();
          });
          vSearch.stop();
        };
      } else {
        alert('Your browser does not support voice recognition!');
      }
    }
  }

  getResult() {
    localStorage.setItem('input_results', this.results);
    this.onChange(this.results, 'search_query');
  }

  clearQuery() {
    this.searchQuery = '';
    this.isAssertionFocused = false;
  }
}

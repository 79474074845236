import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-usercheckcomponent',
    templateUrl: './usercheck.component.html',
    styleUrls: ['./usercheck.component.scss']
})

export class UserCheckComponent implements OnInit {
    userId: string;

    constructor(private route: ActivatedRoute, private router: Router, public userService: UserService) {
        // Remove all items from storage
        this.userService.removeAllItemsFromStorage();

        this.userId = '';
        this.userId = (this.route.snapshot.queryParamMap.get('id')) ? this.route.snapshot.queryParamMap.get('id') : null;
        if (this.userId && this.userId != null) {
            this.userService.setStorageValue('local', 'userId', this.userId);
            this.userService.getUserId(this.userId);
        } else {
            console.log('Redirect to website');
            window.location.href = "https://truthquotient.co/login";
        }
    }

    ngOnInit(): void {
    }
}
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-freetrial',
    templateUrl: './free_trial.component.html',
    styleUrls: ['./free_trial.component.scss']
})
export class FreeTrialComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}